const route = {

    NO_GROUPS: "/groups/no-groups",
    CREATE_GROUPS: '/groups/create-groups',
    MY_GROUPS: '/groups/view-my-groups',
    VIEW_GROUP_SUBSCRIBERS: '/groups/:groupId/view-subscribers',
    ADD_SUBSCRIBERS :'/groups/:groupId/add-subscribers',
    JOIN_GROUPS:'/groups/view-all-groups'

}

export default route