import Role from "./Role"
import { AppConstants } from "./AppConstants"
import AppLogger from "./AppLogger"
import LMSPlatform from "../lms/util/LMSPlatform"

class UserManager {

    static isUserLoggedIn() {

        var accountId = localStorage.getItem('accountId')
        if (accountId) {
            return true
        }
        else {
            return false
        }
    }

    static checkIfInactiveOrResetLastActivityTime() {
        if (this.isUserLoggedIn()) {
            let lastLoggedInTimeInMillis = localStorage.getItem('lastActivityTime')
            let currentTimeInMillis = new Date().getTime()
            if (lastLoggedInTimeInMillis && (currentTimeInMillis - lastLoggedInTimeInMillis > AppConstants.LOCAL_STORAGE_EXPIRATION_TIME_IN_MILLIS)) {
                this.logoutUser()
                //AppLogger.log('clearedLocalStorage', 'InactivityHandler')
            }
            else {
                this.resetLastActivityTime()
            }
        }
    }

    static resetLastActivityTime() {
        //AppLogger.log('resetLastActivityTime', 'InactivityHandler')
        localStorage.setItem('lastActivityTime', new Date().getTime())
    }

    static areGroupsCreated() {
        const areGroupsCreated = localStorage.getItem('areGroupsCreated');
        if (areGroupsCreated === "true") {
            return true
        }
        return false
    }

    static setGroupsCreated(areGroupsCreated) {
        localStorage.setItem('areGroupsCreated', areGroupsCreated);
    }

    static setSelectedLanguage(selectedLanguage) {
        localStorage.setItem('selectedLanguage', selectedLanguage);
    }
    static getSelectedLanguage() {
        return localStorage.getItem('selectedLanguage');
    }

    static setAccountId(userId) {
        localStorage.setItem('accountId', userId);
        this.resetLastActivityTime()
    }

    static getAccountId() {

        return localStorage.getItem('accountId')
    }

    static setAccountRelationId(accountRelationId) {
        localStorage.setItem('accountRelationId', accountRelationId);
    }

    static getAccountRelationId() {

        return localStorage.getItem('accountRelationId')
    }

    static setUserRole(role) {
        localStorage.setItem('userRole', role);
    }

    static getUserRole() {
        return localStorage.getItem('userRole');
    }

    static setTncVersion(version) {
        localStorage.setItem('tncVersion', version);
    }

    static getTncVersion() {
        return localStorage.getItem('tncVersion');
    }

    static setSystemTncVersion(version) {
        localStorage.setItem('systemTncVersion', version);
    }

    static getSystemTncVersion() {
        return localStorage.getItem('systemTncVersion');
    }

    static setEmailId(emailId) {
        localStorage.setItem('emailId', emailId);
    }

    static getEmailId() {
        return localStorage.getItem('emailId');
    }

    static setUserAccountToken(token) {
        localStorage.setItem('accountToken', token);
    }


    static getUserAccountToken() {

        return localStorage.getItem('accountToken');
    }

    static setUserRefreshAccountToken(token) {
        localStorage.setItem('refreshToken', token);
    }


    static getUserRefreshAccountToken() {

        return localStorage.getItem('refreshToken');
    }

    static setAccountRelationType(accountRelationType) {
        localStorage.setItem('accountRelationType', accountRelationType);
    }

    static getAccountRelationType() {
        return localStorage.getItem('accountRelationType');
    }

    static setOrganizationId(organizationId) {
        localStorage.setItem('organizationId', organizationId);
    }

    static getOrganizationId() {
        return localStorage.getItem('organizationId');
    }

    static setOrganizationName(organizationName) {
        localStorage.setItem('organizationName', organizationName);
    }

    static getOrganizationName() {
        return localStorage.getItem('organizationName');
    }

    static setFirstName(firstName) {
        localStorage.setItem('firstName', firstName);
    }

    static getFirstName() {
        return localStorage.getItem('firstName');
    }

    static setLastName(lastName) {
        localStorage.setItem('lastName', lastName);
    }

    static getLastName() {
        return localStorage.getItem('lastName');
    }

    static setIsSubAccountingAllowed(isSubAccountingAllowed) {
        localStorage.setItem('isSubAccountingAllowed', isSubAccountingAllowed);
    }

    static getIsSubAccountingAllowed() {
        return localStorage.getItem('isSubAccountingAllowed');
    }

    static getUserDetails() {
        return localStorage.getItem("userDetails")
    }

    static setUserDetails(userDetails) {

        localStorage.setItem('userDetails', userDetails)

        let obj = JSON.parse(UserManager.getUserDetails());
        this.setAccountId(obj["account-id"])
        this.setUserAccountToken(obj["account-token"])
        this.setUserRefreshAccountToken(obj["refresh-token"])
        this.setOrganizationId(obj["organization-id"])
        this.setOrganizationName(obj["organization-name"])

        this.setFirstName(obj["first-name"])
        this.setLastName(obj["last-name"])
        this.setAccountRelationId(obj["account-relation-id"])
        this.setUserRole(obj["user-role"])
        this.setTncVersion(obj["tnc-version"])
        this.setSystemTncVersion(obj["system-tnc-version"])
        this.setEmailId(obj["email-id"])
        this.setLMSPlatform(obj["ltiPlatform"])


        // not included in the current response
        // if (this.getUserRole() === Role.ADMIN) {
        //     this.setAccountRelationType(obj["organization-type"])            
        //     this.setIsSubAccountingAllowed(obj["is-sub-accounting-allowed"])
        // }

    }

    static getUserName() {
        var userDetails = JSON.parse(this.getUserDetails())
        var userName = userDetails['first-name'] + ' ' + userDetails['last-name']
        return userName
    }


    static setClassDetailsForQuestionListing(selectedClassDetails) {
        localStorage.setItem('selectedClassDetails', selectedClassDetails);
    }

    static getClassDetailsForQuestionListing() {
        return localStorage.getItem('selectedClassDetails');
    }

    static isLoggeIn() {
        var loginFlag = false;
        let obj = JSON.parse(this.getUserDetails());
        if (obj != null) {
            loginFlag = true;
        }
        return loginFlag;
    }

    static logoutUser() {
        const isLMSLogin = this.getLMSPlatform();
        localStorage.clear()
        if(isLMSLogin === LMSPlatform.BIDDLE) {
            this.setLMSPlatform(isLMSLogin);
        }
        if(isLMSLogin && isLMSLogin !== 'undefined') {
            if(!window.location.href.includes('/lms/login')) {
                window.location.href = "/lms/session-expire";
            }
        } else {
            window.location.href="/account/login";
        }
    }

    static setHasShownUnblockPopup(hasShownPopUp){
         localStorage.setItem('hasShownUnblockPopup', hasShownPopUp);
    }

    static getHasShownUnblockPopup(){
        
        const hasShownPopUp = localStorage.getItem('hasShownUnblockPopup');
        if (hasShownPopUp === "true") {
            return true
        }
        return false
    }

    static getExtensionId(){
        let extensionId = localStorage.getItem('extensionId')
        return extensionId
    }

    static setUploadJobDetails(jobDetails) {
        localStorage.setItem('uploadJobDetails', jobDetails);
    }

    static getUploadJobDetails() {
        return localStorage.getItem('uploadJobDetails');
    }


    static setLMSPlatform(lmsPlatform) {
        localStorage.setItem('lmsPlatform', lmsPlatform);
    }

    static getLMSPlatform() {
        return localStorage.getItem('lmsPlatform')
    }
    
    static setAttansionPopupShowOnce(value) {
        return localStorage.setItem('attensionPopupShowOnce', value);
    }

    static getAttansionPopupShowOnce() {
        return localStorage.getItem('attensionPopupShowOnce')
    }

    static clearPreferences()
    {
        localStorage.clear();
    }
}



export default UserManager