const LMSNavigationConstants = {

    IMPORT_QUIZ:"/lms/import-quiz",
    QUIZ_LIST:"/lms/quizzes/:section",
    LTI_LOGIN: "/lms/login",
    LTI_EXAM_LIST: "/lms/import-quiz",
    LMS_RESPONSE:"/lms/quiz/response",
    LIVE_PROCTORING: '/lms/live-proctoring/:exam_id',
    QUIZ_SUBMISSIONS: '/lms/quiz/:exam_master_id/submissions/:exam_id',
    QUIZ_ANALYSIS: '/lms/quiz/:user_exam_id/:user_name/analysis/:exam_master_id/:exam_id',
    LMS_REGISTER_USER: "/lms/register-user",
    WELCOME_USER: '/lms/welcome-user',
    SESSION_EXP: '/lms/session-expire',
    WELCOME_USER: '/lms/welcome-user',
    LMS_CHECK_EXTENSION: '/lms/extension-check',
    ID_CARD_VERIFICATION: '/lms/id-card-verification/:exam_id',
    LMS_STUDENT_DASHBOARD: "/lms/quizes",
    LMS_CANDIDATE_SELECT_SLOT: "/lms/quizes/select-slot/:exam_details",
    IMPORT_ASSIGNMENT:"/lms/import-assignment",
    
    BIDDLE_COMPONENT:"/biddle/:id",
    BIDDLE_LIVE_PROCTORING:"/biddle/live-proctoring/:id",
    BIDDLE_REPORT:"/biddle/quiz/:user_exam_id/:user_name/analysis/:exam_master_id/:exam_id"
}

export default LMSNavigationConstants