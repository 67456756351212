export const AppConstants = {
  GROUPS_PAGE_SIZE: 100,
  PAGE_SIZE: 20,
  PROCTOR_REFRESH_TIME: 30 * 1000,
  PROCTOR_VIDEO_REFRESH_TIME: 10 * 1000,
  MIN_CHAR_TO_START_SEARCH: 3,
  UPCOMMING_EXAM_REFRESH_TIMER: 5 * 1000,
  UPCOMMING_EXAM_STUDENT_REFRESH_TIMER: 60 * 1000,
  SCHEDULE_EXAM_REFRESH_TIMER: 60 * 1000,
  TEST_PAGE_SIZE: 300,
  FIB_BLANK_CHARS: "__________",
  LOCAL_STORAGE_EXPIRATION_TIME_IN_MILLIS: 2 * 60 * 60 * 1000, // 2 hour
  //LOCAL_STORAGE_EXPIRATION_TIME_IN_MILLIS : 1 * 60 * 1000 // n minutes
  MAX_IMG_UPLOAD_SIZE: 2000000,
  MAX_ROWS_UPLOAD_QUESTIONS: 1001,
  IMAGE_SLIDESHOW_INTERVAL: 5,
  EXCEL_FILE_EXTENSION_BYTE_CODE:'504B34',
  MAX_ALLOWED_FILE_SIZE : 1000000
};


export const UpdatedRole = {
  TEACHER : 'Instructor',
  STUDENT : 'Test-taker'
}