class AppLogger {
    static log(logValue,tag = 'TestIO') {
        if (process.env.NODE_ENV == 'development') {
            console.log(tag,new Date().toLocaleDateString(),new Date().toLocaleTimeString(),logValue)
        }
    }
    static info(logValue,tag = 'TestIO') {
        if (process.env.NODE_ENV == 'development') {
            console.info(tag,new Date().toLocaleDateString(),new Date().toLocaleTimeString(),logValue)
        }
    }
    static warn(logValue,tag = 'TestIO') {
        if (process.env.NODE_ENV == 'development') {
            console.warn(tag,new Date().toLocaleDateString(),new Date().toLocaleTimeString(),logValue)
        }
    }
    static error(logValue,tag = 'TestIO') {
        if (process.env.NODE_ENV == 'development') {
            console.error(tag,new Date().toLocaleDateString(),new Date().toLocaleTimeString(),logValue)
        }
    }
    static table(logValue) {
        if (process.env.NODE_ENV == 'development') {
            console.table(logValue)
        }
    }
}
export default AppLogger