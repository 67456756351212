import React, {lazy, Suspense} from 'react';
import { Switch, Redirect } from "react-router-dom";
import UserManager from '../common/UserManager';
import Event from '../analytics/google/CustomEvent';
import Role from '../common/Role';
import OnboardingRoute from '../containers/onboarding/navigation';
import LMSNavigationConstants from '../lms/util/LMSNavigationConstants';

/*ADMIN CLASS IMPORTS*/
import AdminRoutes from '../containers/dashboard/admin/all_groups/navigation'
/*ADMIN CLASS IMPORTS*/

/*TEACHER CLASS IMPORTS*/
import TeacherRoutes from '../containers/dashboard/teacher/my_groups/navigation'
/*TEACHER CLASS IMPORTS*/

/*STUDENT CLASS IMPORTS*/
import StudentRoutes from '../containers/dashboard/student/my_groups/navigation'
import PrivateRoute from './PrivateRoute';
/*STUDENT CLASS IMPORTS*/

/*ADMIN CLASS IMPORTS*/
const AdminNoGroups  = lazy(() => import('../containers/dashboard/admin/all_groups/components/NoGroups'));
const AdminCreateGroups  = lazy(() => import('../containers/dashboard/admin/all_groups/components/CreateGroups'));
const AdminAllGroups  = lazy(() => import('../containers/dashboard/admin/all_groups/components/AllGroups'));
const AdminViewSubscibers  = lazy(() => import('../containers/dashboard/admin/all_groups/components/ViewSubscibers'));
const AdminAddSubscibers  = lazy(() => import('../containers/dashboard/admin/all_groups/components/AddSubscribers'));
/*ADMIN CLASS IMPORTS*/

/*TEACHER CLASS IMPORTS*/
const TeacherNoGroups  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/NoGroups'));
const TeacherCreateGroups  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/CreateGroups'));
const TeacherMyGroups  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/MyGroups'));
const TeacherViewSubscibers  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/ViewSubscibers'));
const TeacherAddSubscibers  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/AddSubscribers'));
const TeacherJoinGroups  = lazy(() => import('../containers/dashboard/teacher/my_groups/components/AllGroups'));
/*TEACHER CLASS IMPORTS*/

/*STUDENT CLASS IMPORTS*/
const StudentMyGroups  = lazy(() => import('../containers/dashboard/student/my_groups/components/MyGroups'));
const StudentViewSubscribers  = lazy(() => import('../containers/dashboard/student/my_groups/components/ViewSubscibers'));
const StudentJoinGroups  = lazy(() => import('../containers/dashboard/student/my_groups/components/AllGroups'));
/*STUDENT CLASS IMPORTS*/

const groupRoute = ({ component: Component, ...rest }) => {
    if (UserManager.getLMSPlatform() === 'undefined' || !UserManager.getLMSPlatform()) {
        const role = UserManager.getUserRole()
        if (role == Role.ADMIN) {
            window.dataLayer.push({
                "role": Role.ADMIN,
                "event": Event.role_push
            })
    
            return (
            <Suspense fallback={<h3 style={{textAlign:"center",height:"100vh",backgroundColor:"#f5f5f5",padding:"20%"}}> Loading...</h3>}>
                <Switch>
                    <PrivateRoute path={AdminRoutes.NO_GROUPS} exact component={AdminNoGroups} />
                    <PrivateRoute path={AdminRoutes.CREATE_GROUPS} exact component={AdminCreateGroups} />
                    <PrivateRoute path={AdminRoutes.ALL_GROUPS} exact component={AdminAllGroups} />
                    <PrivateRoute path={AdminRoutes.VIEW_GROUP_SUBSCRIBERS} exact component={AdminViewSubscibers} />
                    <PrivateRoute path={AdminRoutes.ADD_SUBSCRIBERS} exact component={AdminAddSubscibers} />
                </Switch>
            </Suspense>
            )
        }
        else if (role == Role.TEACHER) {
            window.dataLayer.push({
                "role": Role.TEACHER,
                "event": Event.role_push
            })
    
            return (
            <Suspense fallback={<h3 style={{textAlign:"center",height:"100vh",backgroundColor:"#f5f5f5",padding:"20%"}}> Loading...</h3>}>
                <Switch>
                    <PrivateRoute path={TeacherRoutes.NO_GROUPS} exact component={TeacherNoGroups} />
                    <PrivateRoute path={TeacherRoutes.CREATE_GROUPS} exact component={TeacherCreateGroups} />
                    <PrivateRoute path={TeacherRoutes.MY_GROUPS} exact component={TeacherMyGroups} />
                    <PrivateRoute path={TeacherRoutes.VIEW_GROUP_SUBSCRIBERS} exact component={TeacherViewSubscibers} />
                    <PrivateRoute path={TeacherRoutes.ADD_SUBSCRIBERS} exact component={TeacherAddSubscibers} />
                    <PrivateRoute path={TeacherRoutes.JOIN_GROUPS} exact component={TeacherJoinGroups} />
                </Switch>
            </Suspense>
            )
        }
        else if (role == Role.STUDENT) {
            window.dataLayer.push({
                "role": Role.STUDENT,
                "event": Event.role_push
            })
            return (
            <Suspense fallback={<h3 style={{textAlign:"center",height:"100vh",backgroundColor:"#f5f5f5",padding:"20%"}}> Loading...</h3>}>
                <Switch>
                    <PrivateRoute path={StudentRoutes.MY_GROUPS} exact component={StudentMyGroups} />
                    <PrivateRoute path={StudentRoutes.VIEW_GROUP_SUBSCRIBERS} exact component={StudentViewSubscribers} />
                    <PrivateRoute path={StudentRoutes.ALL_GROUPS} exact component={StudentJoinGroups} />
                </Switch>
            </Suspense>
            )
        }
        else {
            return <Redirect to={OnboardingRoute.LOGIN} />
        }  
    }
    else{
        return <Redirect to={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} />
    }
    
}

export default groupRoute    