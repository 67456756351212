import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Routes from '../containers/onboarding/navigation';
import PrivateRoute from './PrivateRoute';
import LMSNavigationConstants from '../lms/util/LMSNavigationConstants';
import UserManager from '../common/UserManager';


const Login = lazy(() => import('../containers/onboarding/login/components/Login'));
const ChooseRole = lazy(() => import('../containers/onboarding/registration/components/ChooseRole'));
const EnterUserDetails = lazy(() => import('../containers/onboarding/registration/components/EnterUserDetails'));
const EnterSchoolCode = lazy(() => import('../containers/onboarding/registration/components/EnterSchoolCode'));
const VerifyEmail = lazy(() => import('../containers/onboarding/commons/components/VerifyEmail'));
const SubscribeGroups = lazy(() => import('../containers/onboarding/commons/components/SubscribeGroups'));
const PasswordAssistance = lazy(() => import('../containers/onboarding/registration/components/PasswordAssistance'));
const DisplayVerifyEmailDetails = lazy(() => import('../containers/onboarding/registration/components/DisplayVerifyEmailDetails'));
const ResetPassword = lazy(() => import('../containers/onboarding/registration/components/ResetPassword'));
const InstituteMetaDetails = lazy(() => import('../containers/onboarding/registration/components/InstituteMetaDetails'));

const accountRoute = ({ component: Component, ...rest }) => {
	let isLMSPlatform = false;
	let isSessionExpire = false;

	if(UserManager.getLMSPlatform() === 'undefined' || !UserManager.getLMSPlatform()) {
		// || (UserManager.getLMSPlatform() && UserManager.getLMSPlatform().trim() === "")
	if ( window.location !== window.parent.location ) {
			console.log('LMS login platform page is in iframe', true);
			isLMSPlatform = true;
			isSessionExpire = true;
		} else {
			isLMSPlatform = false;
			console.log('LMS login platform page is not in iframe', false);
		}
	} else {
		isLMSPlatform = true;
	}

	if (!isLMSPlatform) {
		return (
			<Suspense fallback={<h3 style={{ textAlign: "center", height: "100vh", backgroundColor: "#f5f5f5", padding: "20%" }}> Loading...</h3>}>
				<Switch>
					<Route path={Routes.LOGIN} exact component={Login} />
					<Route path={Routes.PASSWORD_ASSIST} exact component={PasswordAssistance} />
					<Route path={Routes.RESET_PASSWORD} exact component={ResetPassword} />
					<Route path={Routes.CHOOSE_ROLE} exact component={ChooseRole} />
					<Route path={Routes.ENTER_USER_DETAILS} exact component={EnterUserDetails} />
					<Route path={Routes.ENTER_SCHOOL_CODE} exact component={EnterSchoolCode} />
					<Route path={Routes.DISPLAY_VERIFICATION_EMAIL_DETAILS} exact component={DisplayVerifyEmailDetails} />
					<Route path={Routes.VERIFY_EMAIL} exact component={VerifyEmail} />
					<PrivateRoute path={Routes.SUBSCRIBE_GROUPS} exact component={SubscribeGroups} />
					<PrivateRoute path={Routes.INSTITUTE_DETAILS} exact component={InstituteMetaDetails} />
				</Switch>
			</Suspense>
		)
	} if(isSessionExpire) {
		return <Redirect to={LMSNavigationConstants.SESSION_EXP} />
	}
	else {
		return <Redirect to={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} />
	}
}

export default accountRoute

