
export const navigation = {

        HOME: '/home', // SAME FOR ADMIN TEACHER AND STUDENT
        ALL_GROUPS: "/groups/view-all-groups",
        ALL_FACULTIES: "/all-faculties",
        ALL_STUDENTS: "/all-students",
        TOTAL_TESTS: "/tests",
        SUB_ACCOUNTS: '/sub-accounts',    
        MY_GROUPS: "/groups/view-my-groups",
        MY_QUESTIONS: "/my-questions",
        MY_TESTS: "/my-tests", // SAME FOR ADMIN and TEACHER
        NOTIFICATIONS: "/notifications", // SAME FOR ADMIN TEACHER AND STUDENT
        PROFILE: "/profile" // SAME FOR ADMIN TEACHER AND STUDENT

}

export default navigation