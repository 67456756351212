import config from "../buildConfig"
import { ExamNavigation } from "./ExamNavigation"

export const Common = {
    ROOT : '/',
}

export const ExamNavigationConstants = {
    PROCTOR_TEST: '/teacher/exam/activity/{exam-id}',
    GRADE_TEST: '/exam/check/:exam_id/:exam_name/:grade_type',
    TEACHER_EXAM_ACTIVITY: '/teacher/exam/activity/:exam_id',
    EXAM_REPORTS: '/exam/reports',
    CREATE_PAPER: '/teacher/create-paper',
    EDIT_PAPER: '/teacher/create-paper/:exam_id',
    PREVIEW_PAPER: '/teacher/preview-paper',
    LIVE_PROCTORING: '/teacher/live-proctoring/:exam_id',
    LIVE_PROCTORING_CANVAS: '/teacher/live-proctoring-canvas/:exam_id',
    PROCTORING_REPORT: '/teacher/proctoring-report/:user_exam_id/:user_name',
    SCHEDULE_EXAM:'/teacher/schedule-exam/:exam_id',
    EXAM_REPORT: '/exam/report/:exam_id/:exam_name/:grade_type',
    EXAM_REPORT_DOWNLOAD: '/exam/report/:exam_id/:exam_name/:grade_type/download',
    CHAT_APP: '/teacher/chat-app',
    CHAT_APP_STUD: '/student/chat-app',
    ID_VERIFICATION: '/teacher/id-verification/:exam-id'
}

export const OnboardingNavigationConstants = {
    PROCTOR_TEST: 'SAVED'
}

export const TeacherHomeNavigationConstants = {
    DASHBOARD: '/',
    SELECT_LANGUAGE: '/teacher/create-exam/select-language',
    MY_QUESTIONS_LISTING: '/teacher/myquestions/questionslisting',
    ADD_QUESTION: '/teacher/myquestions/addquestion',
    MY_TESTS : '/tests/:section', 
    STUDENT_DASHBOARD: '/lms/quizes'
}

export const StudentHomeNavigationConstants = {

    DASHBOARD: '/',
    HOME: '/student/home',
    MY_TESTS : '/student/tests',
    EXAM_LISTING: '/student/session',
    START_EXAM: config.liveAppUrl + '/compatibility-check/{user-exam-id}/{relation-id}/{account-token}/{user-name}/{exam-id}/{user-exam-state}/{exam-config}',
    //START_EXAM: 'http://localhost:4546/compatibility-check/{user-exam-id}/{relation-id}/{account-token}/{user-name}/{exam-id}/{user-exam-state}/{exam-config}',

    START_PRACTICE_TEST: config.liveAppUrl + '/practice-compatibility-check/0/{account-token}/{user-name}/{relation-id}',
    //START_PRACTICE_TEST: 'http://localhost:4546/practice-compatibility-check/0/{account-token}/{user-name}/{relation-id}',

    COMPATIBILTY_CHECK: config.liveAppUrl + '/practice-compatibility-check/1/{account-token}/{user-name}/{relation-id}',
    //COMPATIBILTY_CHECK: 'http://localhost:4546/practice-compatibility-check/1/{account-token}/{user-name}/{relation-id}',
    START_EXAM_V1: '/student/start-session/:session_id',
    SCHEDULE_EXAM:'/student/schedule/:exam_master_id',
    UPCOMING_TEST: '/home/upcomingTest',
    RESCHEDULE_EXAM:'/student/reschedule/:exam_master_id/:startDate',
}


export const DashboardNavigationConstants = {

    HOME:'/',
    ALLGROUPS: '/groups/view-all-groups',
    ALLFACULTY: '/all-faculties',
    ALLSTUDENTS:  '/all-students',
    MY_GROUPS : "/groups/view-my-groups",
    PROFILE:"/profile"
}
