enum LMSPlatform {
    CANVAS = 'CANVAS',
    D2L = 'D2L',
    BLACKBOARD = 'BLACKBOARD',
    MOODLE = 'MOODLE',
    BLACKBOARD_ULTRA = "BLACKBOARD_ULTRA",
    JENZABAR = "JENZABAR",
    BIDDLE = "BIDDLE"
}

export default LMSPlatform;
