import DEBUG_SANDBOX from './config/debug-sandbox.json';
import DEBUG_STAGING from './config/debug-staging.json';
import RELEASE from './config/release.json';
import PRE_RELEASE from './config/pre-release.json';
import Environment from './config/Environment';

const config = () => {
    let env = process.env.REACT_APP_ENVIRONMENT ? process.env.REACT_APP_ENVIRONMENT : Environment.PRODUCTION
    console.log('env. => ' + env)
    switch (env.trim()) {
        case Environment.SANDBOX:
            return DEBUG_SANDBOX;
        case Environment.STAGING:
            return DEBUG_STAGING;
        case Environment.PRODUCTION:
            return RELEASE;
        case Environment.PRE_PROD:
            return PRE_RELEASE;
        default:
            return RELEASE;
    }
};

export default config();