import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import AccountRoute from './hoc/AccountRoute'
import HomeRoute from './hoc/HomeRoute';
import GroupRoute from './hoc/GroupRoute';
import RootRoute from './hoc/RootRoute';
import { Common, TeacherHomeNavigationConstants, StudentHomeNavigationConstants, ExamNavigationConstants, DashboardNavigationConstants } from './common/NavigationConstants';
import UserManager from './common/UserManager';
import LMSNavigationConstants from './lms/util/LMSNavigationConstants';

const StudentMyExams = lazy(() => import('./containers/myexam/student/MyExam'));
const AssessmentExamComponent = lazy(() => import('./containers/myexam/teacher/AssessmentExamComponent'));
const AssessmentExamReportComponent = lazy(() => import('./containers/myexam/teacher/AssessmentExamReportComponent'));
const DownloadAnswerSheet = lazy(() => import('./containers/myexam/teacher/DownloadAnswerSheet'));
const ExamReports = lazy(() => import('./containers/examreports/teacher/ExamReports'));
const ScheduleStudentExamComponent = lazy(() => import('./containers/myexam/student/ScheduleStudentExamComponent'));
const RescheduleStudentExamComponent = lazy(() => import('./containers/myexam/student/RescheduleStudentExamComponent'));

const TeacherSelectLanguage = lazy(() => import('./containers/createpaper/teacher/SelectLanguage'));
const StudentSession = lazy(() => import('./containers/student/Session'));
const StartSession = lazy(() => import('./containers/student/StartSession'));
const MyExam = lazy(() => import('./containers/myexam/teacher/MyExam'));
const ExamActivity = lazy(() => import('./containers/myexam/teacher/ExamActivity'));
const MyQuestionsListing = lazy(() => import('./containers/myquestions/teacher/QuestionsListing'));
const AddQuestion = lazy(() => import('./containers/myquestions/teacher/AddQuestion'));
const CreatePaper = lazy(() => import('./containers/createpaper/teacher/CreatePaper'));
const PreviewPaper = lazy(() => import('./containers/createpaper/teacher/PreviewPaper'));
const LiveProctoringComponent = lazy(() => import('./containers/liveproctoring/LiveProctoringComponent'));

const AllFaculty = lazy(() => import('./containers/dashboard/admin/all_faculties/components/AllFaculty'));
const AllStudents = lazy(() => import('./containers/dashboard/admin/all_students/components/AllStudents'));
const WelcomeGroup = lazy(() => import('./containers/dashboard/admin/all_groups/components/Welcome'));

const ScheduleExamComponent = lazy(() => import('./containers/myexam/teacher/scheduleexam/ScheduleExamComponent'));
const ProctoringReportComponent = lazy(() => import('./containers/myexam/teacher/proctoringreport/ProctoringReportComponent'));

const LiveQuizProctoringComponent = lazy(() => import('./lms/liveproctoring/LiveQuizProctoringComponent'));
const IDVerificationComponent = lazy(() => import('./containers/myexam/teacher/idverification/IDVerificationComponent'));
const ImportQuizComponent = lazy(() => import('./lms/quiz/ImportQuizComponent'));
const ImportAssignmentComponent = lazy(() => import('./lms/quiz/ImportAssignmentComponent'));
const QuizListComponent = lazy(() => import('./lms/quiz/QuizListComponent'));
const LmsLogin = lazy(() => import('./lms/onboarding/Login'));
const LmsResponse = lazy(() => import('./lms/quiz/LmsResponse'));
const QuizSubmissions = lazy(() => import('./lms/report/QuizSubmissions'));
const QuizReport = lazy(() => import('./lms/report/component/QuizReport'));
const LmsRegisterUser = lazy(() => import('./lms/onboarding/RegisterUser'));
const LmsWelcomeUser = lazy(() => import('./lms/onboarding/WelcomeUser'));
const SessionExpire = lazy(() => import('./lms/onboarding/SessionExpire'));
const LmsExtensionCheck = lazy(() => import('./lms/onboarding/LmsExtensionCheck'));
const SelectExamSlotComponent = lazy(() => import('./lms/quiz/student/SelectExamSlotComponent'));
const StudentDashboardComponent = lazy(() => import('./lms/quiz/student/StudentDashboardComponent'));
const LMSIDVerificationComponent = lazy(() => import('./lms/idcardverification/component/LMSIDVerificationComponent'));
const BiddleComponent = lazy(()=> import('./BiddleLms/BiddleComponent'));
const BiddleProctorReport = lazy(()=> import('./BiddleLms/BiddleProctoringReport'))
const BiddleLiveProctoringComponent = lazy(()=> import('./BiddleLms/BiddleLiveProctoringComponent'))


function App(props) {

  return (
    <div>
      <Suspense fallback={<h3 style={{ textAlign: "center", height: "100vh", backgroundColor: "#f5f5f5", padding: "20%" }}> Loading...</h3>}>
        {/* <Header /> */}
        <Switch>
          <RootRoute path={Common.ROOT} exact />
          <PrivateRoute path="/welcome" exact component={WelcomeGroup} />
          <HomeRoute path="/home" exact />
          <AccountRoute path="/account/*" exact />
          <GroupRoute path="/groups/*" exact />


          <PrivateRoute path={DashboardNavigationConstants.ALLFACULTY} exact component={AllFaculty} />
          <PrivateRoute path={DashboardNavigationConstants.ALLSTUDENTS} exact component={AllStudents} />
          <PrivateRoute path={TeacherHomeNavigationConstants.MY_TESTS} exact component={MyExam} />
          <PrivateRoute path={StudentHomeNavigationConstants.MY_TESTS} exact component={StudentMyExams} />
          <PrivateRoute path={StudentHomeNavigationConstants.EXAM_LISTING} exact component={StudentSession} />
          <PrivateRoute path={StudentHomeNavigationConstants.START_EXAM_V1} exact component={StartSession} />
          <PrivateRoute path={StudentHomeNavigationConstants.SCHEDULE_EXAM} exact component={ScheduleStudentExamComponent} />
          <PrivateRoute path={StudentHomeNavigationConstants.RESCHEDULE_EXAM} exact component={RescheduleStudentExamComponent} />
          <PrivateRoute path={TeacherHomeNavigationConstants.SELECT_LANGUAGE} exact component={TeacherSelectLanguage} />
          <PrivateRoute path={ExamNavigationConstants.TEACHER_EXAM_ACTIVITY} exact component={ExamActivity} />
          <PrivateRoute exact path={ExamNavigationConstants.GRADE_TEST} component={AssessmentExamComponent} />
          <PrivateRoute exact path={ExamNavigationConstants.EXAM_REPORTS} component={ExamReports} />
          <PrivateRoute path={TeacherHomeNavigationConstants.MY_QUESTIONS_LISTING} exact component={MyQuestionsListing} />
          <PrivateRoute path={TeacherHomeNavigationConstants.ADD_QUESTION} exact component={AddQuestion} />


          <PrivateRoute exact path={ExamNavigationConstants.CREATE_PAPER} component={CreatePaper} />
          <PrivateRoute exact path={ExamNavigationConstants.EDIT_PAPER} component={CreatePaper} />
          <PrivateRoute exact path={ExamNavigationConstants.PREVIEW_PAPER} component={PreviewPaper} />
          <PrivateRoute exact path={ExamNavigationConstants.LIVE_PROCTORING} component={LiveProctoringComponent} />
          <PrivateRoute exact path={ExamNavigationConstants.SCHEDULE_EXAM} component={ScheduleExamComponent} />
          <PrivateRoute exact path={ExamNavigationConstants.EXAM_REPORT} component={AssessmentExamReportComponent} />
          <PrivateRoute exact path={ExamNavigationConstants.EXAM_REPORT_DOWNLOAD} component={DownloadAnswerSheet} />
          <PrivateRoute exact path={ExamNavigationConstants.PROCTORING_REPORT} component={ProctoringReportComponent} />
          <PrivateRoute exact path={ExamNavigationConstants.ID_VERIFICATION} component={IDVerificationComponent} />
          {/* <PrivateRoute exact path={ExamNavigationConstants.CHAT_APP} component={ChatApp} />
        <PrivateRoute exact path={ExamNavigationConstants.CHAT_APP_STUD} component={ChatApp} /> */}

          <PrivateRouteLms path={LMSNavigationConstants.IMPORT_QUIZ} exact component={ImportQuizComponent} />
          <PrivateRouteLms path={LMSNavigationConstants.IMPORT_ASSIGNMENT} exact component={ImportAssignmentComponent} />
          <PrivateRouteLms path={LMSNavigationConstants.QUIZ_LIST} exact component={QuizListComponent} />
          <PrivateRouteLms path={LMSNavigationConstants.LMS_RESPONSE} exact component={LmsResponse} />
          <PrivateRouteLms exact path={LMSNavigationConstants.LIVE_PROCTORING} component={LiveQuizProctoringComponent} />
          <PrivateRouteLms exact path={LMSNavigationConstants.ID_CARD_VERIFICATION} component={LMSIDVerificationComponent} />
          <PrivateRouteLms exact path={LMSNavigationConstants.QUIZ_SUBMISSIONS} component={QuizSubmissions} />
          <PrivateRouteLms exact path={LMSNavigationConstants.QUIZ_ANALYSIS} component={QuizReport} />
          <Route path={LMSNavigationConstants.LTI_LOGIN} exact component={LmsLogin} />
          <Route exact path={LMSNavigationConstants.LMS_REGISTER_USER} component={LmsRegisterUser} />

          <Route path={LMSNavigationConstants.LMS_CHECK_EXTENSION} exact component={LmsExtensionCheck} />
          <Route path={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} exact component={StudentDashboardComponent} />
          <Route path={LMSNavigationConstants.LMS_CANDIDATE_SELECT_SLOT} exact component={SelectExamSlotComponent} />
          <Route path={LMSNavigationConstants.WELCOME_USER} exact component={LmsWelcomeUser} />
          <Route path={LMSNavigationConstants.SESSION_EXP} exact component={SessionExpire} />
          
          <Route path={LMSNavigationConstants.BIDDLE_COMPONENT} exact component={BiddleComponent} />
          <Route exact path={LMSNavigationConstants.BIDDLE_LIVE_PROCTORING} component={BiddleLiveProctoringComponent} />
          <Route exact path={LMSNavigationConstants.BIDDLE_REPORT} component={BiddleProctorReport} isBiddle />
        </Switch>
      </Suspense>
    </div>

  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log(UserManager.getLMSPlatform(),'11122')
  if (!UserManager.getLMSPlatform() || UserManager.getLMSPlatform() === 'undefined') {
    return (
      <Route {...rest} render={(props) => (
        UserManager.isUserLoggedIn()
          ? <Component {...props} />
          : <Redirect to='/account/login' />
      )} />
    )
  } else {
    return <Redirect to={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} />
  }
}


const PrivateRouteLms = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    UserManager.isUserLoggedIn()
      ? <Component {...props} />
      : <Redirect to={LMSNavigationConstants.WELCOME_USER} />
  )} />
)


export default App;
