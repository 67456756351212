import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';
import {I18nextProvider} from 'react-i18next';
import UserManager from './common/UserManager';
import AppLogger from './common/AppLogger';
import {InactivityHandler} from './InactivityHandler';
// import ErrorBoundary from "./tools/ErrorBoundry";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Environment from "./config/Environment";

let env = process.env.REACT_APP_ENVIRONMENT
  ? process.env.REACT_APP_ENVIRONMENT
  : Environment.PRODUCTION;
Sentry.init({
  dsn:
    "https://b09b16731c1646c3b0e7ac9ed9c56e90@o447753.ingest.sentry.io/5428070",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: "ulearn-teacher@" + process.env.npm_package_version,
  environment: env.trim(),
  beforeSend(event) {
    // Modify the event here
    if (event.user) {
      // Don't send user's email address
      //delete event.user.email;
      if (UserManager.isUserLoggedIn()) {
        //send user data to sentry
      }
    }
    AppLogger.log("sentry####11##",event);
    return event;
  },
});


ReactDOM.render(
    // <React.StrictMode>
    /*<ErrorBoundary>*/
        <Router>
            <I18nextProvider>
                <App/>
                <InactivityHandler/>
            </I18nextProvider>
        </Router>
    /*</ErrorBoundary>*/,
    // </React.StrictMode>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();