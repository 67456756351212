import config from '../../buildConfig'

const navigationRoute = {

    LOGIN: "/account/login",
    PASSWORD_ASSIST: "/account/password-assist",
    RESET_PASSWORD: "/account/reset-password",
    CHOOSE_ROLE: "/account/choose-your-role",
    ENTER_USER_DETAILS: "/account/enter-user-details",
    ENTER_SCHOOL_CODE: "/account/enter-code",
    DISPLAY_VERIFICATION_EMAIL_DETAILS: "/account/display-verfication-email-details",
    VERIFY_EMAIL: "/account/verify-email",
    SUBSCRIBE_GROUPS: "/account/subscribe-groups",
    INSTITUTE_DETAILS: "/account/add-institute-details",
    
    commons: {

        PRIVACY_POLICY: config.webUrl + "/privacy-policy",
        TERMS_OF_USE: config.webUrl + "/dashboard",
        TERMS_OFUSE: config.webUrl + "/terms-and-conditions"

    }

}

export default navigationRoute