import React from 'react'
import { Redirect } from "react-router-dom";
import UserManager from '../common/UserManager';
import LMSNavigationConstants from '../lms/util/LMSNavigationConstants';

const RootRoute = ({ component: Component, ...rest }) => {
  if (UserManager.getLMSPlatform() === 'undefined' || !UserManager.getLMSPlatform()) {
    return (
      UserManager.isUserLoggedIn() ? <Redirect to='/home' /> : <Redirect to='/account/login' />
    )
  } else {
    return <Redirect to={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} />
  }
}

export default RootRoute;