import { Component } from 'react'
import UserManager from './common/UserManager';
import AppLogger from './common/AppLogger';

export class InactivityHandler extends Component {    

    componentDidMount(){
        //AppLogger.log('activateActivityTracker','InactivityHandler')
        this.activateActivityTracker()
    }

    componentWillUnmount(){
        //AppLogger.log('disableActivityTracker','InactivityHandler')
        this.disableActivityTracker()
    }

    activateActivityTracker() {
        window.addEventListener("mousemove", this.updateLastActivityTime);
        window.addEventListener("scroll", this.updateLastActivityTime);
        window.addEventListener("keydown", this.updateLastActivityTime);
        window.addEventListener("resize", this.updateLastActivityTime);
    }

    disableActivityTracker() {
        window.removeEventListener("mousemove", this.updateLastActivityTime);
        window.removeEventListener("scroll", this.updateLastActivityTime);
        window.removeEventListener("keydown", this.updateLastActivityTime);
        window.removeEventListener("resize", this.updateLastActivityTime);
    }

    updateLastActivityTime(){
        //AppLogger.log('clearLocalStorageIfRequired','InactivityHandler')
        UserManager.checkIfInactiveOrResetLastActivityTime()
    }

    render() {
        //AppLogger.log('render','InactivityHandler')
        return (
            null
        )
    }
}