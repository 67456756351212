import React, { lazy, Suspense } from 'react';
import { Redirect } from "react-router-dom";
import UserManager from '../common/UserManager';
import OnboardingRoute from '../containers/onboarding/navigation'
import Event from '../analytics/google/CustomEvent'
import Role from '../common/Role'
import Routes from '../containers/dashboard/navigation'
import PrivateRoute from './PrivateRoute'
import LMSNavigationConstants from '../lms/util/LMSNavigationConstants';

const AdminDashboard = lazy(() => import('../containers/dashboard/admin/home/AdminDashboard'));
const TeacherDashboard = lazy(() => import('../containers/dashboard/teacher/home/TeacherDashboard'));
const StudentHome = lazy(() => import('../containers/myexam/student/MyExam'));

const homeRoute = ({ component: Component, ...rest }) => {
    if (UserManager.getLMSPlatform() === 'undefined' || !UserManager.getLMSPlatform()) {
        const role = UserManager.getUserRole()
        if (role) {
            window.dataLayer.push({
                "role": role,
                "event": Event.role_push
            })
        }
        switch (role) {
            case Role.ADMIN: return <Suspense fallback={<h3 style={{ textAlign: "center", height: "100vh", backgroundColor: "#f5f5f5", padding: "20%" }}> Loading...</h3>}><PrivateRoute path={Routes.HOME} exact component={AdminDashboard} /></Suspense>
            case Role.TEACHER: return <Suspense fallback={<h3 style={{ textAlign: "center", height: "100vh", backgroundColor: "#f5f5f5", padding: "20%" }}> Loading...</h3>}><PrivateRoute path={Routes.HOME} exact component={TeacherDashboard} /></Suspense>
            case Role.STUDENT: return <Suspense fallback={<h3 style={{ textAlign: "center", height: "100vh", backgroundColor: "#f5f5f5", padding: "20%" }}> Loading...</h3>}><PrivateRoute path={Routes.HOME} exact component={StudentHome} /></Suspense>
            default: return <Redirect to={OnboardingRoute.LOGIN} />
        }
    }
    else{
        return <Redirect to={LMSNavigationConstants.LMS_STUDENT_DASHBOARD} />
    }

}

export default homeRoute;   