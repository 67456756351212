
export const navigation = {

    NO_GROUPS: "/groups/no-groups",
    MY_GROUPS: '/groups/view-my-groups',
    ALL_GROUPS: '/groups/view-all-groups',
    VIEW_GROUP_SUBSCRIBERS: '/groups/:groupId/view-subscribers',

}

export default navigation